export const ToastErrorStyle = {
  style: {
    backgroundColor: "var(--danger-100)",
    color: "var(--danger-500)",
  },

  iconTheme: {
    primary: "var(--danger-500)",
    secondary: "#ffffff",
  },
};

export const ToastSuccessStyle = {
  style: {
    backgroundColor: "var(--success-100)",
    color: "var(--success-500)",
  },

  iconTheme: {
    primary: "var(--success-500)",
    secondary: "#ffffff",
  },
};

export const ToastWarningStyle = {
  style: {
    backgroundColor: "var(--warning-100)",
    color: "var(--warning-500)",
  },

  iconTheme: {
    primary: "var(--warning-500)",
    secondary: "#FFFAEE",
  },
};

export const ToastInfoStyle = {
  style: {
    backgroundColor: "var(--info-100)",
    color: "var(--info-500)",
  },

  iconTheme: {
    primary: "var(--info-500)",
    secondary: "#FFFAEE",
  },
};
